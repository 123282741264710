
<template>
  <div class="record-box">
    <div class="slogan">
      <img
        src="@/assets/images/dealer/code-slogan.png"
        alt=""
      >
    </div>
    <div class="slogan-title">再有一步即可将财富收入囊中</div>
    <div class="we-chat">
      <img
        :src="imgUrl"
        alt=""
      >
    </div>
    <div class="code-text">
      <p>关注大大汽配公众号 </p>
      <p>或在电脑上打开qipei.dada365.com</p>
      <p>即可完成注册，享受特权挣大钱</p>
    </div>
  </div>

</template>
<script>
export default {
  beforeCreate () {
    document.getElementsByTagName('body')[0].setAttribute('style', 'background:#E34D3C')
  },
  computed: {
    // 通过判断各个环境来展示不同的图片
    imgUrl () {
      if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
        return require('@/assets/images/dealer/wx-code.png')
      } else {
        return require('@/assets/images/dealer/we-chat.png')
      }
    }
  },
  mounted () {
    console.log('process.env.NODE_ENV==', process.env.NODE_ENV)
  }
}
</script>

 <style lang="scss" scoped>
@import "~styles/index.scss";
*html {
  background: #900 !important;
}
.record-box {
  background: url("~images/dealer/code-bg.png") no-repeat top center;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  padding-bottom: px2rem(30px);
}
.we-chat{
  text-align: center;
}
.slogan {
  width: px2rem(475px);
  height: px2rem(163px);
  margin: px2rem(53px) px2rem(136px) 0 px2rem(136px);
  display: inline-block;
}
.slogan-title {
  font-size: px2rem(36px);
  color: #fff;
  text-align: center;
  width: 100%;
  text-align: center;
}
.we-chat img {
  width: px2rem(618px);
  height: px2rem(511px);
  margin: px2rem(281px) auto;
  display: inline-block;
  // margin: px2rem(281px) auto;
}
.code-text {
  color: #fff;
  font-size: px2rem(28px);
  font-weight: 600;
  line-height: px2rem(40px);
  width: 100%;
  text-align: center;
}
</style>
  